/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { AccountNav } from './components/AccountNav';
import { useAuth } from '../auth';
import { useMutation, useQuery } from 'react-query';
import { LayoutSplashScreen } from '../../../_theme/layout/core';
import { Mail } from 'lucide-react';
import { Switch } from 'src/app/components/ui/switch';
import {
  getUserEmailNotifications,
  updateUserEmailNotifications,
} from 'src/app/api/me/notifications';
import { useToast } from 'src/app/hooks/use-toast';

export function EmailNotificationSettings() {
  const Auth = useAuth();
  const { toast: toastSCN } = useToast();
  const [processing, setProcessing] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ['profile.notifications', Auth.currentUser?.email],
    getUserEmailNotifications,
    {
      enabled: !!Auth.currentUser,
    }
  );

  const { mutateAsync } = useMutation(updateUserEmailNotifications);

  if (!Auth.currentUser) {
    return <></>;
  }

  if (!data || isLoading) {
    return <LayoutSplashScreen />;
  }

  const onCheckedChange = async (active: boolean) => {
    setProcessing(true);
    await mutateAsync({
      active,
    });
    await refetch();

    toastSCN({
      title: 'Success',
      description: 'Email Notifications Updated!',
    });
    setProcessing(false);
  };

  return (
    <>
      <div className="d-flex flex-colun flex-lg-row">
        <div className="flex-lg-row-fluid order-lg-1 mb-10 mb-lg-0">
          <div className="card mt-0 mb-7">
            <AccountNav />
          </div>
          <div className="group tw-flex tw-items-center tw-pl-5 tw-rounded-2xl tw-border tw-border-stroke-light  tw-min-h-24 tw-pr-8 hover:tw-no-underline">
            <div className="tw-flex tw-flex-1 tw-items-center tw-justify-between">
              <div className="tw-flex tw-items-center tw-gap-6">
                <div className="tw-size-16 tw-shrink-0 tw-bg-gray-light tw-rounded-xl tw-flex tw-items-center tw-justify-center">
                  <Mail className="tw-text-primary tw-size-10" />
                </div>
                <div>
                  <h3 className="group-hover:tw-underline tw-font-bold">E-mail Notifications</h3>
                  <div className="tw-text-sm">
                    Manage notifications for candidate matches, interviews, and offers.
                  </div>
                </div>
              </div>
              <div
                className="tw-flex-shrink-0 tw-ml-4 tw-w-10"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Switch
                  checked={data.active}
                  disabled={processing}
                  onCheckedChange={onCheckedChange}
                  id="email-notifications-switch"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
