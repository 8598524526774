import { userAPI } from '.';

export interface EmailNotificationResponse {
  active: boolean;
}

export interface UpdateEmailNotificationRequest {
  active: boolean;
}

interface NotificationResponse {
  message: string;
  status: string;
  value: {
    id: string;
    user_id: string;
    allow_email_notifications: boolean;
    allow_ai_assistant: boolean;
  };
  server: string;
  request_uuid: string;
}

export async function getUserEmailNotifications(): Promise<EmailNotificationResponse> {
  const response = await userAPI.get<NotificationResponse>('/settings');

  return {
    active: response.data.value.allow_email_notifications,
  };
}

export async function updateUserEmailNotifications({
  active,
}: UpdateEmailNotificationRequest): Promise<void> {
  await userAPI.post('/settings', {
    allow_email_notifications: active,
  });
}
