import { cn } from 'src/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from 'src/app/components/ui/avatar';

interface UserCardProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl?: string;
  headline?: string;
  className?: string;
}

const UserCard = ({
  id,
  firstName,
  lastName,
  email,
  headline,
  avatarUrl,
  className,
}: UserCardProps) => {
  return (
    <div
      className={cn(
        'tw-w-[220px] tw-bg-gray-light/50 tw-border tw-relative tw-border-stroke-light tw-rounded-2xl tw-px-4 tw-py-3 tw-flex tw-flex-col tw-items-center',
        className
      )}
    >
      <Avatar className="tw-border-4 tw-rounded-[20px] tw-h-[96px] tw-w-[96px]">
        <AvatarImage src={avatarUrl} alt={`${firstName} ${lastName}`} />
        <AvatarFallback className="tw-bg-priBlack tw-rounded-[16px] tw-text-3xl tw-font-bold tw-text-white">{`${firstName[0]}${lastName[0]}`}</AvatarFallback>
      </Avatar>

      <div className="tw-text-base tw-font-semibold tw-mt-2 tw-leading-normal">
        {firstName} {lastName}
      </div>
      <div className="tw-text-sm tw-leading-normal">{email}</div>
      {headline && (
        <div className="tw-text-sm tw-font-medium tw-text-pri tw-leading-normal">{headline}</div>
      )}
    </div>
  );
};

export default UserCard;
