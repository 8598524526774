import { useEffect, useState } from 'react';

interface T {
  handleClose: () => void;
  handleStart: () => void;
  handleStartPractice: () => void;
  paragraphs: string[];
  showPractice?: boolean;
  startText?: string;
}

export const PrepareInterview = ({
  showPractice = true,
  handleClose,
  handleStart,
  handleStartPractice,
  paragraphs,
  startText = 'Start Interview',
}: T) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [error, setError] = useState(false);
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    // Check for Chrome but exclude Edge and Opera
    const isGoogleChrome =
      /Chrome/.test(userAgent) && !/Edg/.test(userAgent) && !/OPR/.test(userAgent);

    setIsChrome(isGoogleChrome);
  }, []);

  const requestMediaAccess = async () => {
    try {
      // Request camera and microphone permissions
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setPermissionGranted(true);
      setError(false);

      // Stop all tracks after granting access
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      setError(true);
      console.error(err);
    }
  };

  const showStatusIcon = (status: boolean) =>
    status ? (
      <i className="bi bi-check-circle-fill !text-green"></i>
    ) : (
      <i className="bi bi-exclamation-triangle-fill !text-orange"></i>
    );

  return (
    <div className="modal-body flex flex-col items-center text-center tw-space-y-2">
      <h3 className="text-h3 font-extrabold">Meet Rebeca, your AI Assistant!</h3>

      <img src="/media/rebeca.png" alt="Rebeca" className="rounded-full w-[120px] my-4" />

      {paragraphs?.map((p) => (
        <p key={p} className="text-body14">
          {p}
        </p>
      ))}

      <p className="text-body14 text-center">
        For the best experience, we recommend using <span className="font-bold">Google Chrome</span>{' '}
        as your browser {showStatusIcon(isChrome)}
      </p>

      <p className="text-body14 text-center">
        Please make sure to allow the browser to{' '}
        <span className="font-bold">access your camera and microphone</span> before starting{' '}
        {showStatusIcon(permissionGranted)}
      </p>

      <div className="flex gap-2">
        <button
          onClick={handleClose}
          className="btn tw-text-sm btn-light btn-active-light !text-darkGray !rounded-[12px]"
        >
          Cancel
        </button>

        {!permissionGranted ? (
          <button
            onClick={requestMediaAccess}
            className="btn btn-primary tw-text-sm !text-white !rounded-[12px]"
          >
            Enable Camera & Mic
          </button>
        ) : (
          <>
            {showPractice && (
              <button
                onClick={handleStartPractice}
                className="btn tw-text-sm main-gradient !text-white !rounded-[12px]"
              >
                Start Practice
              </button>
            )}
            <button
              onClick={handleStart}
              className="btn tw-text-sm btn-outline-primary !rounded-[12px] !text-pri hover:!text-white"
            >
              {startText}
            </button>
          </>
        )}
      </div>

      {error && (
        <p className="text-body14 !font-bold text-alert mt-2 mb-0">
          Permission Denied. Please reset the permissions and allow the camera and mic to continue.
        </p>
      )}
    </div>
  );
};
