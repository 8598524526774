import React, { FC, useRef, useState } from 'react';
import { Config } from '../../../../../config';
import axios from 'axios';
import toast from 'react-hot-toast';

export interface ResumeParserProps {
  onProcessing: () => void;
  onProcessingComplete: () => void;
  allowUsage: boolean;
  processing: boolean;
  disabled: boolean;
}

export const ResumeParser: FC<ResumeParserProps> = (props: ResumeParserProps) => {
  const resumeInputRef = useRef<HTMLFormElement>();
  const [isProcessing, setProcessing] = useState(false);

  const reset = (): void => {
    props.onProcessingComplete();
  };

  const handleClick = (): void => {
    if (resumeInputRef.current) {
      resumeInputRef.current.click();
    }
  };

  function handleResumeChange(event: any) {
    if (fileSizeIsValid(event.target.files[0])) {
      toast.error('File size must be <= 5MB');
      // setFile(null);
      return;
    }
    handleResumeSubmit(event.target.files[0]);
  }

  function handleResumeSubmit(file: File) {
    if (fileSizeIsValid(file)) {
      toast.error('File size must be <= 5MB');
      reset();
      return;
    }

    setProcessing(true);
    props.onProcessing();
    const url = `${Config.api_url}/candidate/profile/resume`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        // setCompleted(true);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        reset();
      })
      .finally(() => {
        setProcessing(false);
        props.onProcessingComplete();
      });
  }

  const fileSizeIsValid = (file: any): boolean => {
    const sizeInMb = file.size / 1024 / 1024;
    if (sizeInMb <= 1 || sizeInMb > 50) {
      return false;
    }

    return true;
  };

  return (
    <>
      {props.allowUsage && (
        <div className={'card mb-7'}>
          <div className="flex p-[14px] items-center justify-between !h-[78px] !bg-priLight00 rounded-[10px]">
            <span className="flex items-center gap-2">
              <img
                src="/media/icons/cute_robot.png"
                alt="Cute Robot"
                className="m-0 p-0 w-[50px] h-[50px] object-cover"
                width={50}
                height={50}
              />

              <div>
                <h3 className="text-h3 font-extrabold mb-1">Resume Parser</h3>
                <p className="p-0 text-body12 m-0">
                  Upload your resume and Distro will parse your information and create your profile.
                  You can confirm details and edit after you upload.
                </p>
              </div>
            </span>

            <input
              ref={resumeInputRef as any}
              type="file"
              accept="application/msword, text/plain, application/pdf"
              style={{ display: 'none' }}
              onChange={handleResumeChange}
            />

            <div
              className={`text-body15 text-pri font-semibold ${
                isProcessing ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={isProcessing ? undefined : handleClick}
            >
              {isProcessing ? (
                <span>Your resume is being parsed, hold tight!</span>
              ) : (
                <span>
                  <i className="bi bi-file-earmark-arrow-up !text-pri !text-body15"></i>Upload your
                  Resume!
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
