import ConfettiExplosion from 'react-confetti-explosion';
import Select from 'react-select';
import { findSalaryRangeByValue, SalaryRanges } from '../../../../util/salary';
import {
  defaultAvailabilities,
  startAvailabityOptions,
} from '../../../../util/startAvailabilityOptions';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useAuth } from '../../../auth';
import { Config } from '../../../../../config';
// import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CandidateProfileModel } from '../../profile/Models';

interface T {
  handleFinish: () => void;
}

export const InterviewFinish = ({ handleFinish }: T) => {
  // const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [processing, setProcessing] = useState(false);
  const [inputData, setInputData] = useState<any>({});
  const [otherAvailability, setOtherAvailability] = useState<string>('');

  const fetchProfile = async (): Promise<CandidateProfileModel> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile`);
    return await response.data.value;
  };

  const { isLoading, data } = useQuery({
    queryKey: ['candidate.profilea', currentUser?.id],
    queryFn: fetchProfile,
  });

  const handleInputUpdate = useCallback(
    (newData: Partial<any>) => {
      setInputData((existingData: any) => ({
        ...existingData,
        ...newData,
      }));
    },
    [inputData]
  );

  useEffect(() => {
    if (data?.start_availability && !defaultAvailabilities.includes(data.start_availability)) {
      setOtherAvailability(data.start_availability);
      data.start_availability = 'other';
    }

    setInputData(data);
  }, [data]);

  const handleSave = async () => {
    setProcessing(true);

    if (inputData.start_availability === 'other') {
      inputData.start_availability = otherAvailability;
    }

    if (inputData.skills instanceof Array) {
      inputData.skills = inputData.skills.join(',');
    }
    if (inputData.languages instanceof Array) {
      inputData.languages = inputData.languages.join(',');
    }
    if (inputData.additional_skills instanceof Array) {
      inputData.additional_skills = inputData.additional_skills.join(',');
    }

    try {
      await axios.post(`${Config.api_url}/candidate/profile`, { ...inputData });
      toast.success(`AI Interview Finished`);
      handleFinish();
      // navigate(`/candidate/welcome`);
    } catch (error: any) {
      let message = error.response?.data.message;
      toast.error(message);
      setInputData((prev: any) => ({ ...prev, start_availability: data?.start_availability }));
    } finally {
      setProcessing(false);
    }
  };

  if (isLoading || !data) {
    return null;
  }

  return (
    <>
      <div className="max-w-[1440px] mx-auto mt-[24px]">
        <img src="/media/logos/logo_dark_prpl.svg" alt="Distro" className="w-[120px]" />
        <h1 className="text-h1 mt-8 font-extrabold text-left">AI Interview</h1>
      </div>

      <section className="flex flex-col w-full items-center justify-center max-w-[1440px] mx-auto">
        <p className="text-[28px] font-normal">That’s it, you did it!</p>
        <p className="text-[22px] font-normal">
          Please just take a moment to update your availability and salary expectation. Until next
          time!
        </p>
        <ConfettiExplosion zIndex={99999} width={1000} />

        <div className="row w-1/2">
          <div className="col-lg-6">
            <p className="required text-body14 font-bold text-priBlack m-0">Salary Range</p>
            <p className="text-body12 text-darkGray m-0 p-0">
              Monthly salary request in USD for full-time work
            </p>
            <Select
              defaultValue={findSalaryRangeByValue(data?.salary_range_in_cents?.toString())}
              placeholder="Select Required Monthly Salary Range ($USD)"
              isClearable={true}
              name="salary_range_in_cents"
              onChange={(e) =>
                handleInputUpdate({
                  salary_range_in_cents: e?.value,
                })
              }
              isMulti={false}
              options={SalaryRanges}
              styles={{
                control: (base) => ({
                  ...base,
                  fontSize: '14px',
                }),
              }}
            />
          </div>

          <div className="col-lg-6">
            <p className="required text-body14 font-bold text-priBlack m-0">Availability</p>
            <p className="text-body12 text-darkGray m-0 p-0">
              When would you be able to start a new role?
            </p>
            <Select
              defaultValue={
                !data.start_availability
                  ? undefined
                  : defaultAvailabilities.includes(data.start_availability as string)
                  ? startAvailabityOptions.find((item) => item.value === data.start_availability)
                  : { value: 'other', label: 'Other' }
              }
              placeholder="Select your Availability"
              isClearable={true}
              name="start_availability"
              onChange={(e) =>
                handleInputUpdate({
                  start_availability: e?.value,
                })
              }
              isMulti={false}
              options={startAvailabityOptions}
            />
          </div>
          {inputData.start_availability === 'other' && (
            <div className="col-lg-6">
              <p className="required text-body14 font-bold text-priBlack m-0">
                Specify your Availability
              </p>
              <p className="text-body12 text-darkGray m-0 p-0 invisible">{' hidden '}</p>
              <input
                type="text"
                defaultValue={otherAvailability}
                onChange={(e) => setOtherAvailability(e.target.value)}
                className="form-control form-control-sm required"
                required
              />
            </div>
          )}
        </div>

        <button
          onClick={handleSave}
          className="btn btn-primary main-button main-gradient mt-4"
          disabled={processing}
        >
          Finish
        </button>
      </section>
    </>
  );
};
