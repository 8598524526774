import axios from 'axios';
import { Config } from 'src/config';

// Base axios instance
export const baseAPI = axios.create({
  baseURL: Config.api_url,
  headers: {
    'Content-Type': 'application/json',
  },
});

// setupAxios(baseAPI);
// Request interceptor
// baseAPI.interceptors.request.use(
//   (config) => {
//     // SAMPLE: Get token from localStorage or your auth service
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Response interceptor
// baseAPI.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // Handle common errors (401, 403, etc.)
//     if (error.response?.status === 401) {
//       // Handle unauthorized
//       // Example: redirect to login
//     }
//     return Promise.reject(error);
//   }
// );
