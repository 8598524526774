import React, { FC, useCallback, useState } from 'react';
import axios from 'axios';
import { Config } from '../../../../../config';
import moment from 'moment/moment';
import toast from 'react-hot-toast';
import { captureException } from '@sentry/react';
import { Modal } from 'react-bootstrap';
import { AnimatedButton } from '../../../../../_theme/helpers/components/AnimatedButton';
import { CandidateCertificationModel } from '../Models';
import { useQuery } from 'react-query';
import { useAuth } from '../../../auth';
import { LayoutSplashScreen } from '../../../../../_theme/layout/core';
import clsx from 'clsx';
import { CertificationItem } from './CertificationItem';

interface CertificationItem {
  name: string;
  date_acquired: undefined | string;
  certificate_id: undefined | string;
}

const CertificationItemDefaultValue = {
  name: '',
  date_acquired: '',
  certificate_id: '',
};

export const Certification: FC<any> = (props) => {
  const { currentUser } = useAuth();
  const [processing, setProcessing] = useState<boolean>(false);
  const [inputData, setInputData] = useState<any>({});
  const [showCertificationModel, setShowCertificationModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<string>('');
  const [certificationItem, setCertificationItem] = useState<CertificationItem>(
    CertificationItemDefaultValue
  );

  const reset = () => {
    setCertificationItem(CertificationItemDefaultValue);
    setInputData({});
    setProcessing(false);
    setShowCertificationModal(false);
  };

  const handleCertificationDelete = useCallback(async (id: string) => {
    setProcessing(true);
    try {
      await axios.delete(`${Config.api_url}/candidate/certifications/${id}`);
      toast.success('Certification Deleted.');
      await refetch();
    } catch (error: any) {
      let message = error.response.data.message;
      toast.error(message);
      captureException(error);
    }
    setProcessing(false);
  }, []);

  const handleCertificationAdded = async () => {
    setCertificationItem(CertificationItemDefaultValue);
    await refetch();
  };

  const handleCertificationInput = useCallback(
    (newData: Partial<any>) => {
      console.log(newData);
      setCertificationItem((existingData: any) => ({
        ...existingData,
        ...newData,
      }));
    },
    [certificationItem]
  );

  const handleAddCertification = useCallback(async () => {
    if (processing) {
      return;
    }

    setProcessing(true);
    try {
      const response = await axios.post(`${Config.api_url}/candidate/certifications`, {
        name: certificationItem.name,
        certificate_id: certificationItem.certificate_id,
        date_acquired: certificationItem.date_acquired
          ? moment(certificationItem.date_acquired)
          : null,
      });
      toast.success('Certificate saved successfully!');
      setShowCertificationModal(false);
      await handleCertificationAdded();
    } catch (e: any) {
      if (e.response) {
        toast.error(e.response?.data.message);
      } else {
        captureException(e);
        toast.error('An error occurred, please try again.');
      }
    } finally {
      setProcessing(false);
    }
  }, [certificationItem]);

  const handleEditCertification = useCallback(
    async (id: string) => {
      if (processing) {
        return;
      }

      setProcessing(true);
      try {
        const response = await axios.put(`${Config.api_url}/candidate/certifications/${id}`, {
          name: certificationItem.name,
          certificate_id: certificationItem.certificate_id,
          date_acquired: certificationItem.date_acquired
            ? moment(certificationItem.date_acquired)
            : null,
        });
        toast.success('Certificate updated successfully!');
        setShowCertificationModal(false);
        await handleCertificationAdded();
      } catch (e: any) {
        if (e.response) {
          toast.error(e.response?.data.message);
        } else {
          captureException(e);
          toast.error('An error occurred, please try again.');
        }
      } finally {
        setProcessing(false);
      }
    },
    [certificationItem]
  );

  const fetchCertifications = async (): Promise<Array<CandidateCertificationModel>> => {
    const response = await axios.get(`${Config.api_url}/candidate/certifications`);
    return await response.data.value;
  };

  const { isLoading, data, isRefetching, refetch } = useQuery({
    queryKey: ['candidate.certifications', currentUser?.id],
    queryFn: fetchCertifications,
  });

  if (isRefetching || isLoading || !data) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <Modal
        centered
        backdrop="static"
        keyboard={false}
        className={'fade'}
        animation={true}
        show={showCertificationModel}
      >
        <Modal.Body className={'modal-md  !px-[32px] !py-[22px]'}>
          <h3 className="text-h3 font-extrabold">Add Certificate</h3>
          <span className="block text-body12 !mb-4">Notifying your employer about your career</span>

          <div className="col-md-12">
            <label className="align-items-center">
              <span className="required text-body14 font-semibold">Certificate Name</span>
            </label>

            <input
              type="text"
              defaultValue={certificationItem.name}
              onChange={(e) =>
                handleCertificationInput({
                  name: e.target.value,
                })
              }
              className="form-control"
              placeholder="e.g. AWS Solutions Architect"
            />
          </div>

          <div className="col-md-12  mt-2">
            <label className="align-items-center">
              <span className="required text-body14 font-semibold">Certificate ID</span>
            </label>
            <input
              type="text"
              defaultValue={certificationItem.certificate_id}
              onChange={(e) =>
                handleCertificationInput({
                  certificate_id: e.target.value,
                })
              }
              className="form-control"
              placeholder="e.g. AWS 00"
            />
          </div>

          <div className="mt-2">
            <label className="align-items-center">
              <span className="required text-body14 font-semibold">Year Earned</span>
            </label>
            <div className="row mb-10">
              <div className="col-md-6">
                <select
                  defaultValue={certificationItem.date_acquired}
                  onChange={(e) =>
                    handleCertificationInput({
                      date_acquired: moment(e.target.value),
                    })
                  }
                  className="form-control form-select form-control-sm"
                  placeholder="Year"
                >
                  {Array.from({ length: 40 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="modal-footer !m-0 !p-0 pb-0 !border-none">
            <button
              type="button"
              disabled={processing}
              className="btn btn-light btn-active-light-primary btn-sm main-button"
              data-bs-dismiss="modal"
              onClick={reset}
            >
              Cancel
            </button>
            <AnimatedButton
              onClick={isEdit ? () => handleEditCertification(isEdit) : handleAddCertification}
              type="button"
              processing={processing}
              disabled={processing}
              className="btn-sm btn btn-primary main-gradient main-button !text-white"
            >
              Save Certificate
            </AnimatedButton>
          </div>
        </Modal.Body>
      </Modal>

      <div className="card">
        <div className="card-body">
          <div
            className={clsx(
              'cursor-pointer flex flex-col justify-center items-center w-full p-[15px] rounded-[10px] !border-dashed !border-pri'
            )}
            style={{ border: '1px dashed #D3D3D3' }}
            onClick={() => {
              setIsEdit('');
              setCertificationItem(CertificationItemDefaultValue);
              setShowCertificationModal(true);
            }}
          >
            <i className="bi bi-plus-lg !text-pri !text-h1 mb-2"></i>
            <span className="text-body14 font-bold">Certificate</span>
            <span className="text-body12 text-darkGray">
              By showcasing your work history, you're painting a vivid picture of your skills,
              experiences, and growth over time.
            </span>
          </div>
          <p className="text-lightGray2 font-semibold text-body14 mt-6">
            Certificate ({data.length})
          </p>
          {data.map((certf) => {
            return (
              <div className="flex items-center my-1" key={certf.id}>
                <div className={'col-md-11'}>
                  <CertificationItem data={certf} />
                </div>
                <div className={'col-md-1 text-end'}>
                  <button
                    onClick={() => {
                      setIsEdit(certf.id);
                      console.log(certf);
                      setCertificationItem({
                        ...certf,
                        date_acquired: moment(certf.date_acquired).format('YYYY'),
                      } as unknown as CertificationItem);
                      setShowCertificationModal(true);
                    }}
                    type="button"
                    disabled={processing}
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary !bg-white"
                  >
                    <i className="bi bi-pencil-fill" />
                  </button>
                  <button
                    onClick={() => handleCertificationDelete(certf.id)}
                    type="button"
                    disabled={processing}
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary !bg-white"
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
