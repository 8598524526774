import React, { useEffect, useState } from 'react';
import { useATS } from './context/ATSContext';
import LoadingScreen from './loading-screen';

const Complete = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { interview, pollInterviewStatus } = useATS();

  useEffect(() => {
    const fetchInterviewData = async () => {
      if (interview?.id) {
        const interviewData = await pollInterviewStatus(interview.id, 10, 1000);
        console.log('interviewd', interviewData);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchInterviewData();
  }, [interview?.id]);
  return (
    <div className="tw-py-4">
      {interview?.status === 'completed' ? (
        <>
          <h2 className="tw-text-2xl tw-font-bold tw-mb-6">Interview Complete</h2>
          <div className="tw-space-y-4">
            <h1>Interview ID: {interview?.id}</h1>
            <h1>Interview Status: {interview?.status}</h1>
            <h1>Interview Score: {interview?.totalScore}</h1>
            <h1>Interview Hard Skills Score: {interview?.totalHardSkillsScore}</h1>
            <h1>Interview Soft Skills Score: {interview?.totalSoftSkillScore}</h1>
            <h1>Interview Profile Score: {interview?.totalProfileScore}</h1>
            <h1>Score Description: {interview?.totalScoreDescription}</h1>
          </div>
        </>
      ) : null}

      {interview?.status !== 'completed' && !isLoading ? (
        <div className="tw-text-center tw-flex tw-flex-col tw-items-center tw-justify-center tw-pb-14 tw-min-h-[calc(100vh-5.5rem)]">
          <h2 className="tw-text-2xl tw-font-bold tw-text-pri">Something Went Wrong</h2>
          <p className="tw-mt-2 tw-text-gray-600">
            The interview could not be completed. Status: {interview?.status || 'unknown'}
          </p>
        </div>
      ) : null}
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default Complete;
