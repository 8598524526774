import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Content } from './components/content';
import { Sidebar } from './components/sidebar';
import { ThemeModeProvider } from '../partials';
import { PageDataProvider } from './core';
import { reInitMenu } from '../helpers';
import { Config } from '../../config';
import { AdminHeader } from './components/header/AdminHeader';

const MasterLayout = () => {
  const location = useLocation();
  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  const environment = Config.env;

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className="d-flex flex-column flex-root app-root" id="distro_app_root">
          <AdminHeader />
          <div className="app-page flex-column flex-column-fluid" id="distro_app_page">
            <div
              className="app-wrapper flex-column flex-row-fluid !ml-[220px]"
              id="distro_app_wrapper"
            >
              <Sidebar />
              <div className="app-main flex-column flex-row-fluid" id="distro_app_main">
                {/* <CandidateHeaderNotice /> */}
                <div className="d-flex flex-column flex-column-fluid">
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                {/*<FooterWrapper />*/}
              </div>
            </div>
          </div>
        </div>
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

export { MasterLayout };
