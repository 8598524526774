import React from 'react';

import { Button } from 'src/app/components/ui/button-legacy';
import { BrowserWebcam } from './BrowserWebcam';

export const InterviewPreviewPage = ({
  handleClose,
  handleStart,
}: {
  handleClose: () => void;
  handleStart: () => void;
}) => {
  return (
    <div className="tw-fixed tw-tailwind-container tw-top-0 tw-left-0 tw-w-screen tw-h-screen tw-bg-white tw-z-[199]">
      <div className="tw-max-w-[1440px] tw-px-[24px] tw-mx-auto tw-mt-[24px]">
        <div className="tw-mb-[18px]">
          <img src="/media/logos/logo_dark_prpl.svg" alt="Distro" className="tw-w-[120px]" />
        </div>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-[1.2fr_1fr] tw-mt-[24px] tw-gap-[24px]">
          <div className="tw-flex tw-flex-col tw-gap-[16px]">
            <h1 className="tw-text-h1 tw-mb-0 tw-font-extrabold tw-text-left tw-w-fit">
              Check Camera and Mic
            </h1>
            <div>
              <BrowserWebcam
                isPreview={true}
                isPractice={false}
                onCancel={() => {}}
                onSave={(file: File, transcript: string) => {
                  return new Promise((resolve) => {
                    resolve(true);
                  });
                }}
                setTimerSeconds={() => {}}
                setTimerStarted={() => {}}
              />
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center">
            <div className="tw-flex tw-flex-col tw-w-[250px] tw-justify-center tw-gap-[16px]">
              <Button onClick={handleStart}>Start Interview</Button>
              <Button variant="secondary" onClick={handleClose}>
                Cancel Interview
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
