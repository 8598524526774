import React from 'react';
import 'src/styles/loader.css';
import { cn } from 'src/lib/utils';
const LoadingScreen = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        'tw-fixed tw-inset-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-white/55 tw-backdrop-blur-sm tw-z-50',
        className
      )}
    >
      <img
        src="/media/logos/logo_dark_prpl.svg"
        alt="Distro"
        className="-tw-ml-[7px] tw-w-[160px]"
      />
      <div className="tw-size-16 tw-flex tw-items-center tw-justify-center tw-mt-4">
        <div className=" tw-scale-150">
          <div className="loader-1">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
