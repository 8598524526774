import React from 'react';
import { Outlet } from 'react-router-dom';
import { ATSProvider, useATS } from './context/ATSContext';
import { Avatar, AvatarImage, AvatarFallback } from '@radix-ui/react-avatar';
import { cn } from 'src/lib/utils';

const ATSLayoutContent = () => {
  const { candidate, showAvatar, isConfirmed } = useATS();
  return (
    <div className="ats-layout tw-min-h-[100vh] tw-max-w-screen-xl tw-mx-auto tw-flex tw-flex-col tw-px-4 2xl:tw-px-0">
      <header className="tw-h-14 tw-w-full tw-mx-auto tw-flex tw-items-center tw-justify-between tw-flex-shrink-0">
        <img
          src="/media/logos/logo_dark_prpl.svg"
          alt="Distro"
          className="-tw-ml-[7px] tw-w-[160px]"
        />

        <div
          className={cn('tw-transition-all tw-duration-300', {
            'tw-opacity-0 tw-scale-[2.3] tw-translate-y-[calc(50vh-170%)] tw-z-50 -tw-translate-x-[calc(min(50vw,640px)-50%)]':
              !showAvatar && !isConfirmed,
            'tw-opacity-0 tw-scale-[2.3] tw-translate-y-[calc(30vh-140%)] tw-z-50 -tw-translate-x-[calc(min(50vw,640px)-85%)]':
              !showAvatar && isConfirmed,
          })}
        >
          <Avatar className="tw-size-11 flex tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-pri">
            <AvatarImage
              className="flex tw-size-9 tw-rounded-full"
              src={candidate?.avatar}
              alt={`${candidate?.firstName} ${candidate?.lastName}`}
            />
            <AvatarFallback className="flex tw-items-center tw-justify-center tw-bg-priBlack !tw-size-9 tw-rounded-full tw-font-bold tw-text-white">{`${candidate?.firstName.charAt(
              0
            )}${candidate?.lastName.charAt(0)}`}</AvatarFallback>
          </Avatar>
        </div>
      </header>
      <div className="tw-flex-1">
        <Outlet />
      </div>
    </div>
  );
};

const ATSLayout = () => {
  return (
    <ATSProvider>
      <ATSLayoutContent />
    </ATSProvider>
  );
};

export default ATSLayout;
