import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { ATSContextValue, ATSCandidate, InterviewStatus, FormValues, formSchema } from './types';
import {
  getCandidateById,
  InterviewDetails,
  saveATSInterviewVideo,
  createATSInterviewByCandidateId,
  getATSInterviewById,
  updateCandidateAvailabilityAndSalaryByCandidateId,
} from 'src/app/api/ats/service';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useToast } from 'src/app/hooks/use-toast';
const ATSContext = createContext<ATSContextValue | undefined>(undefined);

export const useATS = () => {
  const context = useContext(ATSContext);
  if (context === undefined) {
    throw new Error('useATS must be used within an ATSProvider');
  }
  return context;
};

export const ATSProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showAvatar, setShowAvatar] = useState(false);
  const [candidateId, setCandidateId] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isInterviewReady, setIsInterviewReady] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [interviewStatus, setInterviewStatus] = useState<InterviewStatus>('pending');
  const [cancelAttempts, setCancelAttempts] = useState(0);
  const [interviewId, setInterviewId] = useState<string | null>(null);
  const [interview, setInterview] = useState<InterviewDetails | null>(null);
  const [isInterviewLoading, setIsInterviewLoading] = useState(false);
  const [interviewError, setInterviewError] = useState<Error | null>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      salary_range_in_cents: '',
      availability: '',
      start_availability: '',
    },
  });

  const {
    data: candidate,
    isLoading,
    error: candidateError,
  } = useQuery<ATSCandidate, Error>(
    ['candidate', candidateId],
    () => getCandidateById(candidateId!),
    {
      enabled: !!candidateId,
      onSuccess: (data) => {
        form.setValue('salary_range_in_cents', data.salary_range_in_cents);
        form.setValue('availability', data.availability);
        form.setValue('start_availability', data.start_availability);
      },
    }
  );

  const createInterview = useCallback(async () => {
    if (!candidateId || !isConfirmed) return;

    setIsInterviewLoading(true);
    try {
      const {
        id: newInterviewId,
        status,
        message,
      } = await createATSInterviewByCandidateId(candidateId);
      if (status === 'error') {
        setInterviewError(new Error(message));
        return null;
      } else {
        setInterviewId(newInterviewId);
        return newInterviewId;
      }
    } catch (error) {
      setInterviewError(error as Error);
      return null;
    } finally {
      setIsInterviewLoading(false);
    }
  }, [candidateId, isConfirmed]);

  const fetchInterview = useCallback(async (id: string) => {
    setIsInterviewLoading(true);
    try {
      const interviewData = await getATSInterviewById(id);
      setInterview(interviewData);
      return interviewData;
    } catch (error) {
      setInterviewError(error as Error);
      return null;
    } finally {
      setIsInterviewLoading(false);
    }
  }, []);

  const nextQuestion = useCallback(() => {
    if (interview && currentQuestion >= interview.questions.length) {
      setInterviewStatus('completed');
      navigate('/ats-interview/complete');
      return;
    }
    setCurrentQuestion((prev) => prev + 1);
  }, [interview, currentQuestion]);

  const saveVideo = useCallback(
    async (questionIndex: number, file: File, transcription: string) => {
      try {
        if (!interview || !candidateId) return;

        // TODO: Implement the actual API call to save the video
        // This is a placeholder for the actual implementation
        await saveATSInterviewVideo({
          questionId: interview.questions[questionIndex].questionId,
          file,
          candidateId,
          transcription,
        });

        return true;
      } catch (error) {
        console.error('Error saving video:', error);
        return false;
      }
    },
    [interview, candidateId]
  );

  const cancelInterview = useCallback(() => {
    setCancelAttempts((prev) => {
      const newAttempts = prev + 1;

      setCurrentQuestion(1);
      setIsInterviewReady(false);

      // Reset everything only on the third attempt
      if (newAttempts >= 3) {
        // Reset all state to initial values
        setCandidateId(null);
        setIsConfirmed(false);
        setInterviewStatus('cancelled');

        // Navigate back to the starting point
        navigate('/ats-interview');
        return 0;
      }

      return newAttempts;
    });
  }, [navigate]);

  const pollInterviewStatus = useCallback(
    async (id: string, maxAttempts = 30, intervalMs = 2000) => {
      console.log('Starting interview status polling...');
      let attempts = 0;

      const poll = async (): Promise<InterviewDetails | null> => {
        try {
          const interviewData = await fetchInterview(id);
          console.log('Poll attempt', attempts + 1, 'status:', interviewData?.status);

          if (!interviewData) {
            throw new Error('Failed to fetch interview data');
          }

          // Check if we've reached a final status
          if (interviewData.status === 'completed' || interviewData.status === 'failed') {
            console.log('Interview reached final status:', interviewData.status);
            return interviewData;
          }

          // Check if we've exceeded max attempts
          if (++attempts >= maxAttempts) {
            console.log('Max polling attempts reached');
            throw new Error('Interview status polling timeout');
          }

          // Wait for the interval and try again
          await new Promise((resolve) => setTimeout(resolve, intervalMs));
          return poll();
        } catch (error) {
          console.error('Polling error:', error);
          setInterviewError(error as Error);
          return null;
        }
      };

      return poll();
    },
    [fetchInterview]
  );

  const onFormSubmit = async (values: FormValues) => {
    if (
      values.salary_range_in_cents === candidate?.salary_range_in_cents &&
      values.availability === candidate?.availability &&
      values.start_availability === candidate?.start_availability
    ) {
      console.log('No changes');
      navigate('/ats-interview/check-camera');
      return;
    }
    try {
      setIsInterviewLoading(true);
      await updateCandidateAvailabilityAndSalaryByCandidateId(candidateId!, values);
      toast({
        title: 'Success',
        description: 'Candidate details updated successfully',
      });
      navigate('/ats-interview/check-camera');
    } catch (error) {
      console.error('Failed to save details:', error);
    } finally {
      setIsInterviewLoading(false);
    }
  };

  useEffect(() => {
    if (interviewError) {
      toast({
        title: 'Error',
        description: interviewError.message,
        variant: 'destructive',
      });
    }
  }, [interviewError]);

  const value: ATSContextValue = {
    candidateId,
    candidate,
    interview: interview || null,
    interviewId: interviewId || null,
    error: candidateError || interviewError,
    isLoading: isLoading || isInterviewLoading,
    setCandidateId,
    showAvatar,
    setShowAvatar,
    isConfirmed,
    setIsConfirmed,
    isInterviewReady,
    setIsInterviewReady,
    currentQuestion,
    nextQuestion,
    interviewStatus,
    setInterviewStatus,
    cancelInterview,
    saveVideo,
    cancelAttempts,
    createInterview,
    fetchInterview,
    pollInterviewStatus,
    form,
    onFormSubmit,
  };

  return <ATSContext.Provider value={value}>{children}</ATSContext.Provider>;
};
