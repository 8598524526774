import React, { FC, lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { AuthPage, Logout, useAuth, UserTypeEnum } from '../modules/auth';
import { App } from '../App';
import { PrivateRoute } from '../../_theme/helpers/PrivateRoute';
import InvitationPage from '../modules/invitation/InvitationPage';
import { CustomerRoutes } from './CustomerRoutes';
import { CandidateRoutes } from './CandidateRoutes';
import { PrivateRouteByUserType } from '../../_theme/helpers/PrivateRouteByUserType';
import AccountPage from '../modules/account/AccountPage';
import NotificationsPage from '../modules/notifications/NotificationsPage';
import { SuperAdminRoutes } from './SuperAdminRoutes';
import { lazyRetry } from '../util/lazyreload';
import FeedbackPage from '../modules/feedback/FeedbackPage';
import { ATSRoutes } from './ATSRoutes';

const PublicProfilePage = lazy(() =>
  lazyRetry(() => import('../modules/publicprofile/PublicProfilePage'))
);

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser, accountType, isSuperAdmin } = useAuth();
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="ats-interview/*" element={<ATSRoutes />} />
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route path="invitation/*" element={<InvitationPage />} />
          <Route path="feedback_request/*" element={<FeedbackPage />} />
          <Route path="profile/:slug" element={<PublicProfilePage />} />
          {currentUser ? (
            <>
              {isSuperAdmin && <Route path="/superadmin/*" element={<SuperAdminRoutes />} />}
              {/* Redirects to UserType' Dashboard after login success */}
              {accountType === 'customer' && (
                <Route path="auth/*" element={<Navigate to={`/${accountType}/dashboard`} />} />
              )}
              {accountType === 'candidate' && (
                <Route path="auth/*" element={<Navigate to={`/${accountType}/profile/edit`} />} />
              )}
              <Route
                path="/customer/*"
                element={
                  <PrivateRouteByUserType user_type_guard={UserTypeEnum.CUSTOMER}>
                    <CustomerRoutes />
                  </PrivateRouteByUserType>
                }
              />
              <Route
                path="/candidate/*"
                element={
                  <PrivateRouteByUserType user_type_guard={UserTypeEnum.CANDIDATE}>
                    <CandidateRoutes />
                  </PrivateRouteByUserType>
                }
              />
              <Route
                path="account/*"
                element={
                  <PrivateRoute>
                    <AccountPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="notifications/*"
                element={
                  <PrivateRouteByUserType user_type_guard={UserTypeEnum.CUSTOMER}>
                    <NotificationsPage />
                  </PrivateRouteByUserType>
                }
              />
              {/*Default to UserType' Dashboard */}
              {accountType === 'customer' ? (
                <Route index element={<Navigate to={`/${accountType}/dashboard`} />} />
              ) : (
                <Route index element={<Navigate to={`/${accountType}/profile`} />} />
              )}
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
