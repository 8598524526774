import axios from 'axios';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore no module
import ReactStars from 'react-rating-stars-component';
import toast from 'react-hot-toast';
import { useAuth } from '../../auth';
import { Config } from '../../../../config';

interface T {
  handleClose?: () => void;
  interviewType: 'VetAI' | 'InterviewAI';
}

type VetAIFeedbackModel = {
  rating: number;
  experience: string;
  experience_explanation: string;
  relevance: string;
  recommend: string;
  thoughts: string;
};

export const GatherFeedback = ({ handleClose, interviewType }: T) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [feedback, setFeedback] = useState<VetAIFeedbackModel>({
    rating: 0,
    experience: '',
    experience_explanation: '',
    relevance: '',
    recommend: '',
    thoughts: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFeedback((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSendFeedback = () => {
    axios
      .post(`${Config.api_url}/candidate/ai-interview/leave-feedback`, {
        candidate_name: `${currentUser?.first_name} ${currentUser?.last_name}`,
        interview_type: interviewType,
        ...feedback,
      })
      .then(() => {
        toast.success('Your feedback has been submitted successfully');
        navigate('/candidate/welcome');
      })
      .catch((e) => {
        console.error('Failed to send feedback', e);
        toast.error('Failed to send feedback');
      });
  };

  return (
    <div className="flex flex-col p-8">
      <h3 className="text-h3 font-extrabold text-center mb-8">Leave your feedback!</h3>

      <p className="text-body15 font-semibold mb-1 mt-8">Rate Your Experience:</p>
      <ReactStars
        classNames="w-full flex justify-between"
        count={5}
        size={72}
        onChange={(rating: number) => setFeedback((prev) => ({ ...prev, rating }))}
      />
      <div className="flex justify-between text-lightGray2">
        <span>Not at all</span>
        <span>Below Average</span>
        <span>Average</span>
        <span>Satisfied</span>
        <span>Very Satisfied</span>
      </div>

      <p className="text-body15 font-semibold mb-1 mt-8">
        Was the interview process clear and easy to follow?
      </p>
      <span className="grid grid-cols-3 text-darkGray">
        <span className="flex items-center">
          <input type="radio" id="yes" name="experience" value="yes" onChange={handleChange} />
          <label className="ml-1" htmlFor="yes">
            Yes
          </label>
        </span>

        <span className="flex items-center col-span-2">
          <input type="radio" id="no" name="experience" value="no" onChange={handleChange} />
          <label className="ml-1" htmlFor="no">
            No (If no, what could be improved?)
          </label>
        </span>

        {feedback.experience === 'no' && (
          <span className="col-span-3 mt-2">
            <input
              className="form-control form-control-sm"
              placeholder="What are your thoughts..."
              value={feedback.experience_explanation}
              onChange={(e) =>
                setFeedback((prev) => ({ ...prev, experience_explanation: e.target.value }))
              }
            />
          </span>
        )}
      </span>

      <p className="text-body15 font-semibold mb-1 mt-8">
        How would you rate the relevance of the questions to the role you're applying for?
      </p>
      <span className="grid grid-cols-3 text-darkGray">
        <span className="flex items-center">
          <input
            type="radio"
            id="relevant"
            name="relevance"
            value="relevant"
            onChange={handleChange}
          />
          <label className="ml-1" htmlFor="relevant">
            Very Relevant
          </label>
        </span>

        <span className="flex items-center">
          <input
            type="radio"
            id="somewhat"
            name="relevance"
            value="somewhat"
            onChange={handleChange}
          />
          <label className="ml-1" htmlFor="no">
            Somewhat Relevant
          </label>
        </span>

        <span className="flex items-center">
          <input type="radio" id="not" name="relevance" value="not" onChange={handleChange} />
          <label className="ml-1" htmlFor="no">
            Not Relevant
          </label>
        </span>
      </span>

      <p className="text-body15 font-semibold mb-1 mt-8">
        Would you recommend this interview process to others?
      </p>
      <span className="grid grid-cols-3 text-darkGray">
        <span className="flex items-center">
          <input type="radio" id="yes" name="recommend" value="yes" onChange={handleChange} />
          <label className="ml-1" htmlFor="yes">
            Yes
          </label>
        </span>

        <span className="flex items-center">
          <input type="radio" id="no" name="recommend" value="no" onChange={handleChange} />
          <label className="ml-1" htmlFor="no">
            No
          </label>
        </span>
      </span>

      <p className="text-body15 font-semibold mt-8 text-lightGray2 mb-1">
        Please share any additional thoughts or suggestions to help us enhance the experience:
      </p>
      <textarea
        className="form-control form-control-sm resize-none"
        placeholder="Enter additional thoughts"
        value={feedback.thoughts}
        name="thoughts"
        onChange={(e) => setFeedback((prev) => ({ ...prev, thoughts: e.target.value }))}
      />

      <div className="flex justify-center w-full gap-x-4 mt-16">
        <button
          onClick={handleClose}
          className="btn btn-light main-button btn-active-light !text-darkGray !rounded-[12px]"
        >
          Cancel
        </button>

        <button
          onClick={() => {
            handleSendFeedback();
            handleClose?.();
          }}
          className="btn btn-light main-gradient !text-white main-button btn-active-light !rounded-[12px]"
        >
          Submit Feedback
        </button>
      </div>
    </div>
  );
};
