import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/app/components/ui/button';
import { BrowserWebcam } from 'src/app/modules/candidate/AiInterviews/BrowserWebcam';
import { useATS } from './context/ATSContext';
import { TimerReset } from 'lucide-react';
import LoadingScreen from './loading-screen';

export const CheckCameraAndMic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    candidateId,
    setIsInterviewReady,
    cancelAttempts,
    setInterviewStatus,
    createInterview,
    fetchInterview,
  } = useATS();
  const navigate = useNavigate();

  const handleStopRecording = () => {
    document.getElementById('stop-recording-btn')?.click();
  };

  const handleStartInterview = async () => {
    handleStopRecording();
    setIsInterviewReady(true);
    setIsLoading(true);
    console.log('Creating interview...');
    const interviewId = await createInterview();
    console.log('Interview created with ID:', interviewId);

    if (interviewId) {
      console.log('Fetching interview details...');
      await fetchInterview(interviewId);
      console.log('Interview details fetched');
      const attempt =
        cancelAttempts === 0
          ? 'firstAttempt'
          : cancelAttempts === 1
          ? 'secondAttempt'
          : 'thirdAttempt';
      console.log('Setting interview status:', attempt);
      setInterviewStatus(attempt);

      console.log('Navigating to interview page...');
      setIsLoading(false);
      navigate(`/ats-interview/interview`);
    } else {
      setIsLoading(false);
    }
  };

  const handleCancel = async () => {
    handleStopRecording();
    navigate(`/ats-interview/${candidateId}`);
  };

  return (
    <>
      {' '}
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-[1.4fr_1fr] tw-gap-4">
        <div className="tw-flex tw-flex-col tw-gap-3">
          <h1 className="tw-text-h1 tw-h-[33px] tw-flex tw-items-end tw-mb-0 tw-font-extrabold tw-text-left tw-w-fit">
            Check Camera and Mic
          </h1>
          <div className="tw-flex tw-h-[25px] tw-w-full tw-items-center tw-px-2 tw-opacity-50 tw-justify-between">
            <div className="tw-flex tw-items-center tw-gap-2.5">
              <TimerReset className="tw-stroke-gray-light2 -tw-scale-x-100 tw-size-6" />
              <div className="tw-flex tw-gap-0.5 tw-items-center">
                <span className="tw-text-priBlack tw-text-lg tw-font-semibold mr-1">03:00</span>
                <span className="tw-text-priBlack tw-text-xs tw-font-semibold">Sec</span>
                <span className="tw-text-pri tw-text-xs tw-font-normal">/ Left</span>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2.5">
              <div className="tw-text-sm tw-font-medium tw-text-priBlack">Progress</div>
              <ul className="tw-flex tw-gap-2">
                <li className="tw-w-8 tw-h-[5px] tw-bg-pri tw-rounded-full"></li>
                <li className="tw-w-8 tw-h-[5px] tw-bg-pri tw-rounded-full"></li>
                <li className="tw-w-8 tw-h-[5px] tw-bg-pri tw-rounded-full"></li>
                <li className="tw-w-8 tw-h-[5px] tw-bg-pri tw-rounded-full"></li>
                <li className="tw-w-8 tw-h-[5px] tw-bg-pri tw-rounded-full"></li>
                <li className="tw-w-8 tw-h-[5px] tw-bg-pri tw-rounded-full"></li>
                <li className="tw-w-8 tw-h-[5px] tw-bg-pri tw-rounded-full"></li>
                <li className="tw-w-8 tw-h-[5px] tw-bg-pri tw-rounded-full"></li>
              </ul>
            </div>
          </div>
          <div className="tw-flex-grow">
            <BrowserWebcam
              isPreview={true}
              isPractice={false}
              onCancel={() => {}}
              onSave={(file: File, transcript: string) => {
                return new Promise((resolve) => {
                  resolve(true);
                });
              }}
              setTimerSeconds={() => {}}
              setTimerStarted={() => {}}
            />
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-center">
          <div className="tw-flex tw-flex-col tw-w-[250px] tw-justify-center tw-gap-[16px]">
            <Button onClick={handleStartInterview}>Start Interview</Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel Interview
            </Button>
          </div>
        </div>
      </div>
      {isLoading && <LoadingScreen className="!tw-bg-white" />}
    </>
  );
};

export default CheckCameraAndMic;
