import { ATSCandidate, FormValues } from 'src/app/modules/ats/context/types';
import { atsAPI, atsInterviewAPI } from '.';
import { Config } from 'src/config';
import { ApiResponse } from '../types';

interface Job {
  id: string;
  organization_id: string;
  title: string;
  skills: string[];
  description: string;
  job_role_id: string | null;
  timezone: string;
  salary_range_cents_max: number;
  salary_range_cents_min: number;
  commitment_type: string;
  status: string;
  open_roles: number;
  created_by: string | null;
  pay_frequency: string;
  additional_skills: string[];
  skills_seniority: string | null;
  key_questions: string | null;
  years_experience: string | null;
  regions: string | null;
  work_model: string | null;
  state: string | null;
  city: string | null;
  salary_range_dollars_min: string;
  salary_range_dollars_max: string;
}

interface CandidateResponse {
  data: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    job_id: string;
    full_name: string;
    initials: string;
    first_name_last_name_initial: string;
    job: Job;
  };
}

export interface Candidate {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  job_id: string;
  full_name: string;
  initials: string;
  first_name_last_name_initial: string;
  job: Job;
  salary_range_cents_max: number;
  salary_range_cents_min: number;
  availability: string;
  start_availability: string;
}

export const getCandidateById = async (candidateId: string): Promise<ATSCandidate> => {
  try {
    const {
      data: { value },
    } = await atsAPI.get<ApiResponse<Candidate>>(`/candidate/${candidateId}`);
    const data = value[0];
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      jobTitle: data.job.title,
      avatar: '',
      salary_range_in_cents: `${data.salary_range_cents_min}-${data.salary_range_cents_max}`,
      availability: data.availability,
      start_availability: data.start_availability,
    };
  } catch (error) {
    // ... error handling ...
    console.error('getCandidateById error', error);
    return {
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
      avatar: '',
      salary_range_in_cents: '',
      availability: '',
      start_availability: '',
    };
  }
};

// Types for better type safety
interface CreateInterviewResponse {
  ats_candidate_id: string;
  status: string;
  total_score: number;
  job_id: string;
  id: string;
}

// Internal types (camelCase)
interface InterviewQuestionResponse {
  id: string;
  ats_interview_id: string;
  question: string;
  question_title: string;
  type: string;
  answer: string | null;
  score: number;
  description: string | null;
  data: any | null;
}

interface InterviewDetailsResponse {
  id: string;
  ats_candidate_id: string;
  status: 'pending' | 'scoring' | 'completed' | 'failed';
  total_score: number;
  total_score_description: string | null;
  total_soft_skill_score: number | null;
  total_profile_score: number | null;
  total_hard_skills_score: number | null;
  job_id: string;
  questions: InterviewQuestionResponse[];
}

// External types (camelCase for frontend use)
export interface InterviewQuestion {
  questionId: string;
  question: string;
  questionTitle: string;
}

export interface InterviewDetails {
  id: string;
  questions: InterviewQuestion[];
  status: InterviewDetailsResponse['status'];
  totalScore: number;
  totalScoreDescription: string | null;
  totalSoftSkillScore: number | null;
  totalProfileScore: number | null;
  totalHardSkillsScore: number | null;
}

// Payload types
export interface SubmitAnswerPayload {
  questionId: string;
  path: string;
  convertToMp4: boolean;
  transcription: string;
}

interface SubmitAnswerRequest {
  question_id: string;
  path: string;
  convert_to_mp4: boolean;
  transcription: string;
}

// Create a new ATS Interview
export const createATSInterviewByCandidateId = async (
  candidateId: string
): Promise<{
  id: string;
  status: 'success' | 'error';
  message: string;
}> => {
  try {
    const { data } = await atsInterviewAPI.post<ApiResponse<CreateInterviewResponse>>('/create', {
      candidate_id: candidateId,
    });
    if (data.status === 'error') {
      return {
        id: '',
        status: 'error',
        message: data.message,
      };
    }
    return {
      id: data.value[0].id,
      status: 'success',
      message: 'Interview created successfully',
    };
  } catch (error: any) {
    console.error('createATSInterviewByCandidateId error', error);
    return {
      id: '',
      status: 'error',
      message: error?.response?.data?.message || 'Failed to create interview',
    };
  }
};

// Get interview details including questions
export const getATSInterviewById = async (aiInterviewId: string): Promise<InterviewDetails> => {
  const {
    data: { value },
  } = await atsInterviewAPI.get<ApiResponse<InterviewDetailsResponse>>(`/view/${aiInterviewId}`);
  const data = value[0];
  return {
    id: data.id,
    questions: data.questions.map((q) => ({
      questionId: q.id,
      question: q.question,
      questionTitle: q.question_title,
    })),
    status: data.status,
    totalScore: data.total_score,
    totalScoreDescription: data.total_score_description,
    totalSoftSkillScore: data.total_soft_skill_score,
    totalProfileScore: data.total_profile_score,
    totalHardSkillsScore: data.total_hard_skills_score,
  };
};

// Submit answer for a question
export const submitATSInterviewAnswer = async (payload: SubmitAnswerPayload): Promise<void> => {
  const requestPayload: SubmitAnswerRequest = {
    question_id: payload.questionId,
    path: payload.path,
    convert_to_mp4: payload.convertToMp4,
    transcription: payload.transcription,
  };

  await atsInterviewAPI.post('/video', requestPayload);
};

// Create interview and get questions in one operation
export const createAndGetATSInterview = async (candidateId: string): Promise<InterviewDetails> => {
  // First create the interview and get the ID
  const interviewId = '9e1f6991-dc7b-4e6d-b1ec-81d95e297f03'; //await createATSInterviewByCandidateId(candidateId);

  // Then fetch the interview details including questions
  return await getATSInterviewById(interviewId);
};

interface SaveVideoPayload {
  questionId: string;
  file: File;
  candidateId: string;
  transcription: string;
  interviewType?: string;
}

export const saveATSInterviewVideo = async (payload: SaveVideoPayload): Promise<void> => {
  const { questionId, file, candidateId, transcription, interviewType = 'ats' } = payload;
  console.log('saveATSInterviewVideo payload', payload);
  // Create custom filename
  const customFileName = `${interviewType}-question-${questionId}-user-${candidateId}_${new Date().getTime()}`;
  const fullLocation = `https://${Config.services.aws.bucket}.s3.amazonaws.com/${customFileName}`;

  // Get presigned URL
  const s3response = await atsInterviewAPI.post(`/save`, {
    candidateId,
    questionId,
    fileName: customFileName,
    fileType: file.type,
  });
  const presignedUrl = s3response?.data?.url;

  // Upload to S3
  await fetch(presignedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  });

  // Submit answer with S3 location
  await submitATSInterviewAnswer({
    questionId,
    path: fullLocation,
    convertToMp4: true,
    transcription, // Transcription will be handled separately if needed
  });
};

interface AudioResponse {
  value: {
    file: string; // base64 encoded audio file
  };
}

export const getQuestionAudio = async (text: string): Promise<HTMLAudioElement> => {
  const response = await atsInterviewAPI.get<AudioResponse>(`/audio`, {
    params: {
      text,
    },
  });

  // Convert base64 to Audio object
  const byteCharacters = atob(response.data.value.file);
  const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'audio/mpeg' });
  const url = URL.createObjectURL(blob);

  return new Audio(url);
};

export const updateCandidateAvailabilityAndSalaryByCandidateId = async (
  candidateId: string,
  form: FormValues
): Promise<void> => {
  await atsAPI.patch(`/candidate/${candidateId}`, form);
};
