import { UseFormReturn } from 'react-hook-form';
import { InterviewDetails } from 'src/app/api/ats/service';
import { SalaryRanges } from 'src/app/util/salary';
import { startAvailabityOptions } from 'src/app/util/startAvailabilityOptions';
import { z } from 'zod';

export const availabilityOptions = [
  { value: 'open', label: 'Open' },
  { value: 'available', label: 'Available' },
  { value: 'unavailable', label: 'Not Available' },
];
// HOME_URL/interview?token={secureToken}
export const formSchema = z.object({
  salary_range_in_cents: z.enum(SalaryRanges.map((v) => String(v.value)) as [string, ...string[]], {
    errorMap: () => ({ message: 'Please select a salary range' }),
  }),
  availability: z.enum(availabilityOptions.map((v) => v.value) as [string, ...string[]], {
    errorMap: () => ({ message: 'Please select availability status' }),
  }),
  start_availability: z.enum(startAvailabityOptions.map((v) => v.value) as [string, ...string[]], {
    errorMap: () => ({ message: 'Please select start availability' }),
  }),
});

export type FormValues = z.infer<typeof formSchema>;
export interface ATSCandidate {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  avatar?: string;
  salary_range_in_cents: string;
  availability: string;
  start_availability: string;
}

export interface ATSContextState {
  candidate?: ATSCandidate | null;
  isLoading: boolean;
  error: Error | null;
}

export type InterviewStatus =
  | 'pending'
  | 'firstAttempt'
  | 'secondAttempt'
  | 'thirdAttempt'
  | 'completed'
  | 'cancelled';

export interface ATSContextValue extends ATSContextState {
  candidateId: string | null;
  setCandidateId: (candidateId: string | null) => void;
  showAvatar: boolean;
  setShowAvatar: (showAvatar: boolean) => void;
  interview: InterviewDetails | null;
  isConfirmed: boolean;
  setIsConfirmed: (isConfirmed: boolean) => void;
  isInterviewReady: boolean;
  setIsInterviewReady: (isInterviewReady: boolean) => void;
  currentQuestion: number;
  nextQuestion: () => void;
  interviewStatus: InterviewStatus;
  setInterviewStatus: (interviewStatus: InterviewStatus) => void;
  saveVideo: (
    questionIndex: number,
    file: File,
    transcript: string
  ) => Promise<boolean | undefined>;
  cancelInterview: () => void;
  cancelAttempts: number;
  interviewId: string | null;
  fetchInterview: (id: string) => Promise<InterviewDetails | null>;
  createInterview: () => Promise<string | null | undefined>;
  pollInterviewStatus: (
    id: string,
    maxAttempts?: number,
    intervalMs?: number
  ) => Promise<InterviewDetails | null>;
  form: UseFormReturn<FormValues>;
  onFormSubmit: (values: FormValues) => void;
}

export interface InterviewQuestion {
  id: string;
  question: string;
  answer: string;
  isAnswered: boolean;
}
