import { useState, useCallback, useEffect } from 'react';

interface CountdownConfig {
  minutes: number;
  seconds?: number;
  warningThresholdSec?: number; // in seconds
}

interface UseCountdownReturn {
  start: () => void;
  stop: () => void;
  reset: () => void;
  isRunning: boolean;
  timeDisplay: string;
  isComplete: boolean;
  isWarning: boolean;
}

export const useCountdown = ({
  minutes: initialMinutes,
  seconds: initialSeconds = 0,
  warningThresholdSec: warningThreshold = 0,
}: CountdownConfig): UseCountdownReturn => {
  const initialTime = initialMinutes * 60 + initialSeconds;
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          // Check if we've entered warning threshold
          if (newTime <= warningThreshold) {
            setIsWarning(true);
          }
          if (newTime <= 0) {
            setIsRunning(false);
            setIsComplete(true);
            return 0;
          }
          return newTime;
        });
      }, 1000);
    }

    // Automatically complete when time reaches zero
    if (timeLeft === 0 && !isComplete) {
      setIsRunning(false);
      setIsComplete(true);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRunning, timeLeft, isComplete, warningThreshold]);

  const start = useCallback(() => {
    if (timeLeft > 0) {
      setIsRunning(true);
      setIsComplete(false);
    }
  }, [timeLeft]);

  const stop = useCallback(() => {
    setIsRunning(false);
  }, []);

  const reset = useCallback(() => {
    setTimeLeft(initialTime);
    setIsRunning(false);
    setIsComplete(false);
    setIsWarning(false);
  }, [initialTime]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const timeDisplay = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;

  return {
    start,
    stop,
    reset,
    isRunning,
    timeDisplay,
    isComplete,
    isWarning,
  };
};
