import { useState } from 'react';
import clsx from 'clsx';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { resetPassword } from '../core/_requests';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { AnimatedButton } from '../../../../_theme/helpers/components/AnimatedButton';
YupPassword(Yup);

const initialValues = {
  email: '',
  password: '',
  password_confirmation: '',
};

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('New Password is required')
    .min(
      8,
      'New Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
    )
    .minLowercase(1, 'New Password must contain at least 1 lower case letter')
    .minUppercase(1, 'New Password must contain at least 1 upper case letter')
    .minNumbers(1, 'New Password must contain at least 1 number')
    .minSymbols(1, 'New Password must contain at least 1 special character'),
  password_confirmation: Yup.string()
    .required('Password Confirmation is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  email: Yup.string().email('Wrong email format').required('Email is required'),
});

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      resetPassword(values.email, values.password, values.password_confirmation, token)
        .then(({ data: { result } }) => {
          setHasErrors(false);
          setLoading(false);
          toast.success('Password reset successful!');
          navigate('/auth/login');
        })
        .catch((error) => {
          const status = error.response?.status;
          const errors = error.response?.data?.errors;
          let message = error.response.data.status;
          if (status === 422 && errors) {
            message = '';
            Object.values(errors).forEach((k: any) => {
              k.forEach((v: any) => {
                message += `- ${v}<br/>`;
              });
            });
          }
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus(message);
        });
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="distro_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
      </div>

      {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      <div className="fv-row mb-8">
        <label className="form-label fs-6">Email</label>
        <input
          type="email"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx('form-control form-control-sm bg-transparent')}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-8">
        <label className="form-label fs-6">Password</label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx('form-control form-control-sm bg-transparent')}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-8">
        <label className="form-label fs-6">Confirm Password</label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password_confirmation')}
          className={clsx('form-control form-control-sm bg-transparent')}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password_confirmation}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-grid">
        <AnimatedButton
          type="submit"
          id="distro_password_reset_submit"
          disabled={loading}
          processing={loading}
          className="btn btn-lg btn-primary w-100 mb-10"
        >
          <span className="indicator-label">Submit</span>
        </AnimatedButton>
        <div className="text-gray-500 text-center fw-semibold fs-6">
          <Link to="/auth/login" className="link-primary">
            Cancel
          </Link>
        </div>
      </div>
    </form>
  );
}
