import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { MutationCache, Query, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PostHogProvider } from 'posthog-js/react';
import * as Sentry from '@sentry/react';
import Moment from 'react-moment';
import toast, { Toaster } from 'react-hot-toast';
import { Config } from './config';
import { AppRoutes } from './app/routing/AppRoutes';
import * as authHelper from './app/modules/auth';
import { AuthProvider, setupAxios } from './app/modules/auth';
import { SplashScreenProvider } from './_theme/layout/core';
import { getIso8601Format } from './app/util/helpers';
import { Toaster as ToasterSCN } from './app/components/ui/toaster';

import './styles/legacy.css';
import './index.css';
import './_theme/assets/sass/style.scss';
import './_theme/assets/sass/plugins.scss';
import './_theme/assets/sass/style.react.scss';
import './styles/tailwind.css';

setupAxios(axios);
Moment.globalFormat = 'MMM D, YYYY';
Moment.globalParse = getIso8601Format() as string;

Sentry.init({
  dsn: Config.services.sentry.key,
  environment: Config.env,
  tracesSampleRate: 0.4,
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any, query: Query) => {
      const status = error.response?.status;
      if (status == 401) {
        toast.error(`Please Authenticate.`);
        authHelper.removeAuth();
        location.replace(Config.app_url);
      }
      if (status == 403) {
        toast.error(
          `You do not have permission to view this page.  Please contact your organization admin.`
        );
        Sentry.captureException(error);
        console.error(error);
        authHelper.removeAuth();
        location.replace(Config.app_url);
      }
      //  only show error toasts if we already have data in the cache which indicates a failed background update
      else if (query.state.data !== undefined) {
        toast.error(`Something went wrong, please try your request again.`);
      }
      Sentry.captureException(error);
      console.error(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: any) => {
      const status = error.response?.status;
      if (status !== 422) {
        toast.error(`Something went wrong, please try your request again.`);
        Sentry.captureException(error);
        console.error(error);
      }
    },
  }),
  defaultOptions: {
    queries: {
      // SEE: https://tanstack.com/query/v4/docs/guides/window-focus-refetching
      refetchOnWindowFocus: false,

      // SEE: https://tanstack.com/query/v4/docs/guides/suspense
      suspense: true,
    },
  },
});
const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <PostHogProvider
      apiKey={Config.services.posthog.key}
      options={{ api_host: Config.services.posthog.host }}
    >
      <SplashScreenProvider>
        <QueryClientProvider client={queryClient}>
          <ToasterSCN />
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={24}
            containerClassName=""
            containerStyle={{ margin: '0 0 10px 0' }}
            toastOptions={{
              className: '',
              duration: 3000,
              style: {
                background: 'var(--distro-purple-darker)',
                color: '#fff',
                animation: 'custom-exit 1s ease',
                transition: 'all 0.5s ease-out',
              },
            }}
          />
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SplashScreenProvider>
    </PostHogProvider>
  );
}
