import { Route, Routes } from 'react-router-dom';

// Import ATS components
import CheckCameraAndMic from 'src/app/modules/ats/check-camera-and-mic';
import Interview from 'src/app/modules/ats/interview';
import PreviewResume from 'src/app/modules/ats/preview-resume';
import PreviewUser from '../modules/ats/preview-user';
import ATSLayout from '../modules/ats/layout';
import Complete from '../modules/ats/complete';

const ATSRoutes = () => {
  return (
    <Routes>
      <Route element={<ATSLayout />}>
        <Route path="check-camera" element={<CheckCameraAndMic />} />
        <Route path="interview" element={<Interview />} />
        <Route path="preview-resume" element={<PreviewResume />} />
        <Route path=":candidateId" element={<PreviewUser />} />
        <Route path="complete" element={<Complete />} />
      </Route>
    </Routes>
  );
};

export { ATSRoutes };
