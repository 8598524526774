/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { login, resendEmailVerification } from '../core/_requests';
import { useAuth } from '../core/Auth';
import toast from 'react-hot-toast';
import { AnimatedButton } from '../../../../_theme/helpers/components/AnimatedButton';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [emailNotVerified, setEmailNotVerified] = useState<boolean>(false);
  const { saveAuth, setCurrentUser, setAccountType } = useAuth();
  const navigate = useNavigate();

  const resendEmailVerificationHandler = async () => {
    if (!email) {
      toast.error('Please enter your email');
      return;
    }

    setLoading(true);
    await resendEmailVerification(email as string);
    setLoading(false);
    toast.success('Verification Email Sent');
    navigate('/');
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setStatus(false);
      setEmailNotVerified(false);
      setEmail(values.email);
      try {
        const { data: resp } = await login(values.email, values.password);
        const user = {
          access_token: resp.value.authorization.access_token,
          expires_in: resp.value.authorization.expires_in,
          user: resp.value.user,
        };
        saveAuth(user);
        setCurrentUser(user.user);
        setAccountType(user.user.user_type);
      } catch (error: any) {
        const response = error?.response;
        const status = error?.response.status;
        let message = 'The login information is incorrect.';

        if (status == 422) {
          message = response.data.message;
        }

        if (
          response.data.message &&
          response.data.message.toLowerCase().includes('email not verified')
        ) {
          setEmailNotVerified(true);
        }

        setStatus(message);
        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="distro_login_signin_form"
    >
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            {emailNotVerified && (
              <span>
                <span className="h6 alert-danger">Email not yet verified.</span> Didn't receive
                email?{' '}
                <button
                  disabled={loading}
                  type="button"
                  className="btn-sm btn-flush btn text-primary btn-link"
                  onClick={resendEmailVerificationHandler}
                >
                  {!loading && <>Resend</>}
                  {loading && <>Sending...</>}
                </button>
              </span>
            )}
            {!emailNotVerified && formik.status}
          </div>
        </div>
      )}

      <div className="fv-row mb-8">
        <label className="form-label fw-bolder fs-6 mb-0">Email</label>
        <input
          {...formik.getFieldProps('email')}
          className={clsx('form-control form-control-sm bg-transparent')}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-3">
        <label className="form-label fw-bolder fs-6 mb-0">Password</label>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx('form-control form-control-sm bg-transparent')}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex flex-stack flex-wrap gap-3 fs-6 fw-semibold mb-8">
        <Link to="/auth/forgot-password" className="link-primary">
          Forgot Password ?
        </Link>
      </div>

      <div className="d-grid mb-10">
        <AnimatedButton
          type="submit"
          id="distro_sign_in_submit"
          className="btn btn-primary btn-sm"
          processing={loading}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className="indicator-label">Continue</span>
        </AnimatedButton>
      </div>

      <div className="text-gray-500 text-center fw-semibold fs-6">
        <Link to="/auth/registration" className="link-primary">
          Sign up
        </Link>
      </div>
    </form>
  );
}
