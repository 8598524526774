import axios from 'axios';
import { baseAPI } from '../axios';

// Organization-specific axios instance
export const atsAPI = axios.create({
  baseURL: `${baseAPI.defaults.baseURL}/ats`,
});

// You can add organization-specific interceptors if needed
atsAPI.interceptors.request.use(
  (config) => {
    // Add any organization-specific headers or modifications
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Organization-specific axios instance
export const atsInterviewAPI = axios.create({
  baseURL: `${baseAPI.defaults.baseURL}/ats-interview`,
});

// You can add organization-specific interceptors if needed
atsAPI.interceptors.request.use(
  (config) => {
    // Add any organization-specific headers or modifications
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
